<template>

  <footer class="footer footer-panel mt-5" >
    <div class="content has-text-centered">
      <div>
        © 2024 Swamp of Bugs. All Rights Reserved.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterBottom'
}
</script>

<style scoped>
.height-adder{
  height: 150px;
}
.footer-panel {
  width: 100%;
  padding: 40px 0;
  text-align: center;
  background-color: #f5f5f5;
  color: #333;
  margin-top: auto;
  
}
</style>